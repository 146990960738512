import UilChatInfo from '@iconscout/react-unicons/icons/uil-chat-info';
import { Badge } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from '@pezhmanparsaee/react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { UserActionDropDown } from './auth-info-style';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';
import { DataService } from '../../../config/dataService/dataService';

const NotificationBox = React.memo(() => {
  const { t, i18n } = useTranslation();
  const { rtl } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
    };
  });

  function renderThumb({ style }) {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} />;
  }

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div className="hello" style={thumbStyle} />;
  };

  function renderView({ style }) {
    const customStyle = {
      marginRight: rtl && 'auto',
      [rtl ? 'marginLeft' : 'marginRight']: '-17px',
    };
    return <div style={{ ...style, ...customStyle }} />;
  }

  renderThumb.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  renderView.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };
  const [list, setList] = useState([]);
  const [content, setContent] = useState(null);

  async function fetchChatUnread() {
    DataService.get(`v1/admin/auth/user/chat-unread`, null, { Localization: i18n.language }).then((response) => {
      if (response.data.code === '200') {
        console.log(response.data.data);
        setList(response.data.data);
      }
    });
  }

  useEffect(() => {
    fetchChatUnread();
    setInterval(() => {
      fetchChatUnread();
    }, 10000);
  }, []);

  useEffect(() => {
    if (list.length) {
      console.log(list.length, 'baris');
      setContent(
        <UserActionDropDown className="ninjadash-top-dropdown">
          {/* <Heading as="h5" className="ninjadash-top-dropdown__title">
            <span className="title-text">Notifications</span>
            <Badge className="badge-success" count={3} />
          </Heading> */}
          <Scrollbars
            autoHeight
            autoHeightMin={100}
            autoHeightMax={400}
            autoHide
            renderThumbVertical={renderThumb}
            renderView={renderView}
            renderTrackVertical={renderTrackVertical}
            renderTrackHorizontal={(props) => (
              <div {...props} style={{ display: 'none' }} className="track-horizontal" />
            )}
          >
            <ul className="ninjadash-top-dropdown__nav notification-list">
              {list.map((value) => {
                return (
                  <li>
                    <Link to={`/admin/project/${value.subject.project_id}/subject?chatId=${value.subject.id}`}>
                      <div className="ninjadash-top-dropdown__content notifications">
                        <div className="notification-icon bg-primary">
                          <UilChatInfo />
                        </div>
                        <div className="notification-content d-flex">
                          <div className="notification-text">
                            <Heading as="h5">
                              <span>{value.subject.name}</span> {t('sentMessage')}
                            </Heading>
                          </div>
                          <div className="notification-status">
                            {value.admin_read > 0 ? (
                              <Badge size="default" count={value.admin_read} style={{ paddingLeft: '0px' }} />
                            ) : (
                              <Badge size="default" dot />
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Scrollbars>
        </UserActionDropDown>,
      );
    }
  }, [list]);

  return (
    <div className="ninjadash-nav-actions__item ninjadash-nav-actions__notification">
      <Popover placement="bottomLeft" content={list.length ? content : null}>
        <Badge dot={list.length > 0} offset={[-8, -5]}>
          <Link to="#" className="ninjadash-nav-action-link">
            <ReactSVG src={require('../../../static/img/icon/envelope.svg').default} />
          </Link>
        </Badge>
      </Popover>
    </div>
  );
});

export default NotificationBox;
