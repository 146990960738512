import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/v1/admin/auth/login', values, { Localization: 'ja' });
      if (response.data.code !== '200') {
        const message = response.data.message ? response.data.message : 'Unauthorized';
        dispatch(loginErr(message));
        callback(loginErr(message));
      } else {
        Cookies.set('access_token', response.data.data.access_token);
        Cookies.set('logedIn', true);

        const userResponse = await DataService.get('/v1/admin/auth/user');
        if (userResponse.data.code === '200') {
          Cookies.set('userLoggedIn', userResponse.data.data);
        }

        dispatch(loginSuccess(true));
        callback(loginSuccess(true));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const fbLogin = (callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      Cookies.set('logedIn', true);
      dispatch(loginSuccess(true));
      callback();
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const register = (values) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/register', values);
      if (response.data.errors) {
        dispatch(loginErr('Registration failed!'));
      } else {
        dispatch(loginSuccess(false));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      Cookies.remove('logedIn');
      Cookies.remove('access_token');
      Cookies.remove('userLoggedIn');
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, register, fbLogin };
